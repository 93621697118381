import '../css/app.css';

import Alpine from "alpinejs";
// import QrScanner from "qr-scanner";

// window.QrScanner = QrScanner;
window.Alpine = Alpine;

import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'


Alpine.start();

Livewire.on("openModal", () => {
    console.log("startQrScanner");
});

document.addEventListener("DOMContentLoaded", () => {
    new KeenSlider(
        '#my-slider',
        {
            loop: true,
            centered: true,
            breakpoints: {
                '(min-width: 640px)': {
                    slides: {perView: 2, spacing: 5},
                },
                '(min-width: 1000px)': {
                    slides: {perView: 5, spacing: 30},
                },

            },
            slides: {perView: 1, origin: 'center',},
            created: () => {
                console.log('created')
            },
        },
        [
            (slider) => {
                let timeout
                let mouseOver = false

                function clearNextTimeout() {
                    clearTimeout(timeout)
                }

                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider.next()
                    }, 3000)
                }

                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })
                slider.on("dragStarted", clearNextTimeout)
                slider.on("animationEnded", nextTimeout)
                slider.on("updated", nextTimeout)
            }
        ]
    )

});
